import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Button, Table } from 'react-bootstrap';

const NikkeGuidesBondPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Bond Ranks</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_bond.png"
            alt="Bond Ranks"
          />
        </div>
        <div className="page-details">
          <h1>Bond Ranks</h1>
          <h2>A guide for the Bond system in NIKKE.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Bond System" />
        <p>
          The Bond system is one of the ways to make your characters stronger
          and earn some additional Gems. To enter the Bond screen, either head
          to the Outpost and enter the Command Center - inside you will find the
          Advise option. Alternatively, you can directly access the screen from
          your Characters screen by tapping the Advise button in the top-right
          corner.
        </p>
        <p>Then you will see this screen:</p>
        <StaticImage
          src="../../../images/nikke/generic/bond_1.jpg"
          alt="Bonds"
        />
        <p>
          All the characters you own will be listed here and once you tap on one
          of them, another screen will open:
        </p>
        <StaticImage
          src="../../../images/nikke/generic/bond_3.jpg"
          alt="Bonds"
        />
        <p>
          On this screen, you can either play the Advise mini-game where you
          need to answer a question and if you got it right, you will get 120
          bond rank points, but if you get it wrong, you will only get 100. We
          are currently working on creating a list of the right answers, but for
          now you can use this guide prepared by our friends from Japan (need to
          use Google Chrome auto-translate):
        </p>
        <Button
          variant="primary"
          href="https://kamigame.jp/nikke/page/235723357717281814.html"
          target="_blank"
          rel="noreferrer"
        >
          Advise Answers (JPN)
        </Button>
        <br /> <br />
        <p>
          Here's another page that has the answers in English, but it only has
          the base characters released:
        </p>
        <Button
          variant="primary"
          href="https://rentry.org/nikgadvice"
          target="_blank"
          rel="noreferrer"
        >
          Advise Answers (ENG)
        </Button>
        <br /> <br />
        <p>
          Alternatively, you can give Gifts to your characters and this will
          instantly increase their Bond Level:
        </p>
        <StaticImage
          src="../../../images/nikke/generic/bond_2.jpg"
          alt="Bonds"
        />
        <SectionHeader title="Bond Ranks" />
        <p>
          As we previously mentioned, the Bond system will make your characters
          stronger by giving them additional stats (HP, ATK and DEF) based on
          the Rank you've achieved. The stat bonus depends on the type of the
          character and is different for Attackers, Defenders and Supporters.
        </p>
        <p>
          Also, do keep in mind that initially the max rank you can achieve is
          10 and you need to Limit Break the character to increase the limit -
          with the maximum level being 40 once you MLB a character (so you need
          the initial copy + 3 dupes).{' '}
          <strong>
            Only Pilgrims can reach the level 40 bond though - the other
            manufacturers are capped at level 30
          </strong>
          .
        </p>
        <p>
          The table below will show how much stats you gain on each rank and how
          much points you need to obtain it.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Bond Level</th>
              <th>Req. Points</th>
              <th>Attacker HP</th>
              <th>Attacker ATK</th>
              <th>Attacker DEF</th>
              <th>Defender HP</th>
              <th>Defender ATK</th>
              <th>Defender DEF</th>
              <th>Supporter HP</th>
              <th>Supporter ATK</th>
              <th>Supporter DEF</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>150</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
            </tr>
            <tr>
              <td>2</td>
              <td>310</td>
              <td>649</td>
              <td>29</td>
              <td>5</td>
              <td>793</td>
              <td>19</td>
              <td>6</td>
              <td>721</td>
              <td>24</td>
              <td>5</td>
            </tr>
            <tr>
              <td>3</td>
              <td>320</td>
              <td>1 490</td>
              <td>66</td>
              <td>10</td>
              <td>1 822</td>
              <td>44</td>
              <td>12</td>
              <td>1 656</td>
              <td>55</td>
              <td>11</td>
            </tr>
            <tr>
              <td>4</td>
              <td>330</td>
              <td>2 425</td>
              <td>108</td>
              <td>16</td>
              <td>2 963</td>
              <td>72</td>
              <td>20</td>
              <td>2 694</td>
              <td>90</td>
              <td>18</td>
            </tr>
            <tr>
              <td>5</td>
              <td>340</td>
              <td>3 425</td>
              <td>152</td>
              <td>23</td>
              <td>4 186</td>
              <td>102</td>
              <td>28</td>
              <td>3 805</td>
              <td>127</td>
              <td>25</td>
            </tr>
            <tr>
              <td>6</td>
              <td>350</td>
              <td>4 476</td>
              <td>199</td>
              <td>30</td>
              <td>5 470</td>
              <td>133</td>
              <td>36</td>
              <td>4 973</td>
              <td>166</td>
              <td>33</td>
            </tr>
            <tr>
              <td>7</td>
              <td>360</td>
              <td>5 571</td>
              <td>247</td>
              <td>37</td>
              <td>6 809</td>
              <td>165</td>
              <td>45</td>
              <td>6 190</td>
              <td>206</td>
              <td>41</td>
            </tr>
            <tr>
              <td>8</td>
              <td>370</td>
              <td>6 702</td>
              <td>298</td>
              <td>45</td>
              <td>8 192</td>
              <td>198</td>
              <td>55</td>
              <td>7 447</td>
              <td>248</td>
              <td>50</td>
            </tr>
            <tr>
              <td>9</td>
              <td>380</td>
              <td>7 868</td>
              <td>349</td>
              <td>52</td>
              <td>9 616</td>
              <td>233</td>
              <td>64</td>
              <td>8 742</td>
              <td>291</td>
              <td>58</td>
            </tr>
            <tr>
              <td>10</td>
              <td>390</td>
              <td>9 062</td>
              <td>403</td>
              <td>60</td>
              <td>11 076</td>
              <td>269</td>
              <td>74</td>
              <td>10 069</td>
              <td>336</td>
              <td>67</td>
            </tr>
            <tr>
              <td>11</td>
              <td>400</td>
              <td>10 283</td>
              <td>457</td>
              <td>68</td>
              <td>12 569</td>
              <td>305</td>
              <td>84</td>
              <td>11 426</td>
              <td>381</td>
              <td>76</td>
            </tr>
            <tr>
              <td>12</td>
              <td>420</td>
              <td>11 529</td>
              <td>512</td>
              <td>77</td>
              <td>14 091</td>
              <td>342</td>
              <td>94</td>
              <td>12 810</td>
              <td>427</td>
              <td>85</td>
            </tr>
            <tr>
              <td>13</td>
              <td>430</td>
              <td>12 798</td>
              <td>569</td>
              <td>86</td>
              <td>15 642</td>
              <td>379</td>
              <td>105</td>
              <td>14 220</td>
              <td>474</td>
              <td>95</td>
            </tr>
            <tr>
              <td>14</td>
              <td>440</td>
              <td>14 088</td>
              <td>626</td>
              <td>94</td>
              <td>17 218</td>
              <td>418</td>
              <td>114</td>
              <td>15 653</td>
              <td>522</td>
              <td>104</td>
            </tr>
            <tr>
              <td>15</td>
              <td>450</td>
              <td>15 398</td>
              <td>684</td>
              <td>103</td>
              <td>18 820</td>
              <td>456</td>
              <td>125</td>
              <td>17 109</td>
              <td>570</td>
              <td>114</td>
            </tr>
            <tr>
              <td>16</td>
              <td>470</td>
              <td>16 727</td>
              <td>744</td>
              <td>112</td>
              <td>20 445</td>
              <td>496</td>
              <td>136</td>
              <td>18 586</td>
              <td>620</td>
              <td>124</td>
            </tr>
            <tr>
              <td>17</td>
              <td>480</td>
              <td>18 075</td>
              <td>803</td>
              <td>121</td>
              <td>22 091</td>
              <td>535</td>
              <td>147</td>
              <td>20 083</td>
              <td>669</td>
              <td>134</td>
            </tr>
            <tr>
              <td>18</td>
              <td>500</td>
              <td>19 438</td>
              <td>864</td>
              <td>130</td>
              <td>23 758</td>
              <td>576</td>
              <td>158</td>
              <td>21 598</td>
              <td>720</td>
              <td>144</td>
            </tr>
            <tr>
              <td>19</td>
              <td>510</td>
              <td>20 819</td>
              <td>925</td>
              <td>139</td>
              <td>25 445</td>
              <td>617</td>
              <td>169</td>
              <td>23 132</td>
              <td>771</td>
              <td>154</td>
            </tr>
            <tr>
              <td>20</td>
              <td>530</td>
              <td>22 214</td>
              <td>988</td>
              <td>149</td>
              <td>27 150</td>
              <td>658</td>
              <td>182</td>
              <td>24 682</td>
              <td>823</td>
              <td>165</td>
            </tr>
            <tr>
              <td>21</td>
              <td>540</td>
              <td>23 624</td>
              <td>1 050</td>
              <td>158</td>
              <td>28 874</td>
              <td>700</td>
              <td>193</td>
              <td>26 249</td>
              <td>875</td>
              <td>175</td>
            </tr>
            <tr>
              <td>22</td>
              <td>560</td>
              <td>25 049</td>
              <td>1 114</td>
              <td>167</td>
              <td>30 615</td>
              <td>742</td>
              <td>205</td>
              <td>27 832</td>
              <td>928</td>
              <td>186</td>
            </tr>
            <tr>
              <td>23</td>
              <td>580</td>
              <td>26 487</td>
              <td>1 177</td>
              <td>176</td>
              <td>32 373</td>
              <td>785</td>
              <td>216</td>
              <td>29 430</td>
              <td>981</td>
              <td>196</td>
            </tr>
            <tr>
              <td>24</td>
              <td>590</td>
              <td>27 938</td>
              <td>1 242</td>
              <td>186</td>
              <td>34 146</td>
              <td>828</td>
              <td>228</td>
              <td>31 042</td>
              <td>1 035</td>
              <td>207</td>
            </tr>
            <tr>
              <td>25</td>
              <td>610</td>
              <td>29 402</td>
              <td>1 307</td>
              <td>196</td>
              <td>35 936</td>
              <td>871</td>
              <td>240</td>
              <td>32 669</td>
              <td>1 089</td>
              <td>218</td>
            </tr>
            <tr>
              <td>26</td>
              <td>630</td>
              <td>30 878</td>
              <td>1 373</td>
              <td>206</td>
              <td>37 740</td>
              <td>915</td>
              <td>252</td>
              <td>34 309</td>
              <td>1 144</td>
              <td>229</td>
            </tr>
            <tr>
              <td>27</td>
              <td>650</td>
              <td>32 366</td>
              <td>1 439</td>
              <td>216</td>
              <td>39 558</td>
              <td>959</td>
              <td>264</td>
              <td>35 962</td>
              <td>1 199</td>
              <td>240</td>
            </tr>
            <tr>
              <td>28</td>
              <td>670</td>
              <td>33 865</td>
              <td>1 505</td>
              <td>226</td>
              <td>41 391</td>
              <td>1 003</td>
              <td>276</td>
              <td>37 628</td>
              <td>1 254</td>
              <td>251</td>
            </tr>
            <tr>
              <td>29</td>
              <td>690</td>
              <td>35 376</td>
              <td>1 572</td>
              <td>236</td>
              <td>43 238</td>
              <td>1 048</td>
              <td>288</td>
              <td>39 307</td>
              <td>1 310</td>
              <td>262</td>
            </tr>
            <tr>
              <td>30</td>
              <td>710</td>
              <td>36 897</td>
              <td>1 640</td>
              <td>246</td>
              <td>45 097</td>
              <td>1 094</td>
              <td>300</td>
              <td>40 997</td>
              <td>1 367</td>
              <td>273</td>
            </tr>
            <tr>
              <td>31</td>
              <td>730</td>
              <td>38 430</td>
              <td>1 708</td>
              <td>257</td>
              <td>46 970</td>
              <td>1 138</td>
              <td>314</td>
              <td>42 700</td>
              <td>1 423</td>
              <td>285</td>
            </tr>
            <tr>
              <td>32</td>
              <td>750</td>
              <td>39 972</td>
              <td>1 776</td>
              <td>266</td>
              <td>48 854</td>
              <td>1 184</td>
              <td>326</td>
              <td>44 413</td>
              <td>1 480</td>
              <td>296</td>
            </tr>
            <tr>
              <td>33</td>
              <td>770</td>
              <td>41 524</td>
              <td>1 846</td>
              <td>277</td>
              <td>50 752</td>
              <td>1 230</td>
              <td>339</td>
              <td>46 138</td>
              <td>1 538</td>
              <td>308</td>
            </tr>
            <tr>
              <td>34</td>
              <td>800</td>
              <td>43 086</td>
              <td>1 915</td>
              <td>287</td>
              <td>52 660</td>
              <td>1 277</td>
              <td>351</td>
              <td>47 873</td>
              <td>1 596</td>
              <td>319</td>
            </tr>
            <tr>
              <td>35</td>
              <td>820</td>
              <td>44 658</td>
              <td>1 985</td>
              <td>298</td>
              <td>54 582</td>
              <td>1 323</td>
              <td>364</td>
              <td>49 620</td>
              <td>1 654</td>
              <td>331</td>
            </tr>
            <tr>
              <td>36</td>
              <td>840</td>
              <td>46 238</td>
              <td>2 056</td>
              <td>309</td>
              <td>56 514</td>
              <td>1 370</td>
              <td>377</td>
              <td>51 376</td>
              <td>1 713</td>
              <td>343</td>
            </tr>
            <tr>
              <td>37</td>
              <td>870</td>
              <td>47 828</td>
              <td>2 125</td>
              <td>319</td>
              <td>58 456</td>
              <td>1 417</td>
              <td>389</td>
              <td>53 142</td>
              <td>1 771</td>
              <td>354</td>
            </tr>
            <tr>
              <td>38</td>
              <td>900</td>
              <td>49 427</td>
              <td>2 197</td>
              <td>329</td>
              <td>60 411</td>
              <td>1 465</td>
              <td>403</td>
              <td>54 919</td>
              <td>1 831</td>
              <td>366</td>
            </tr>
            <tr>
              <td>39</td>
              <td>920</td>
              <td>51 035</td>
              <td>2 268</td>
              <td>340</td>
              <td>62 376</td>
              <td>1 512</td>
              <td>416</td>
              <td>56 705</td>
              <td>1 890</td>
              <td>378</td>
            </tr>
            <tr>
              <td>40</td>
              <td>950</td>
              <td>52 650</td>
              <td>2 340</td>
              <td>351</td>
              <td>64 350</td>
              <td>1 560</td>
              <td>429</td>
              <td>58 500</td>
              <td>1 950</td>
              <td>390</td>
            </tr>
          </tbody>
        </Table>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesBondPage;

export const Head: React.FC = () => (
  <Seo
    title="Bond Ranks | NIKKE | Prydwen Institute"
    description="A guide for the Bond system in NIKKE."
  />
);
